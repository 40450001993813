import { Icon } from "@iconify/react/dist/iconify.js";
import { ComboboxItem, Group, Loader, Select } from "@mantine/core";
import { useDidUpdate } from "@mantine/hooks";
import cuid2 from "@paralleldrive/cuid2";
import { useFetcher } from "@remix-run/react";
import React, { useEffect, useRef, useState } from "react";

import { stringIsEmpty } from "~/lib/bool-helpers";
import { Logger } from "~/lib/logger";
import { toQueryString } from "~/lib/object-helpers";
import { mergeArrays } from "~/lib/options";

import { InputSelectParams } from "./input-types";

export type queryParamType = {
  s?: string;
  page: number;
  limit: number;
};

function InputSelect<TData, TLoader, TParams = Record<string, any>>(
  params: InputSelectParams<TData, TParams>,
) {
  const {
    label,
    name,
    form,
    error,
    data = [],
    searchable = true,
    fetchData,
    onChange,
    key,
    optMaxHeight,
    ...props
  } = params;

  // VAR: FETCH DATA
  const {
    urlPath,
    keys: { label: labelKey, value: valueKey },
    dataNestedKeys,
    valueOpt,
    otherParams,
    disabledRowByValues,
    hiddenRowByValues,
  } = fetchData ?? { keys: {} };

  // STATE: DATA LOADING TEMP
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // STATE: SEARCH TEMP
  const [search, setSearch] = useState<string>("");

  // STATE: SEARCH TEMP
  const [focus, setFocus] = useState<boolean>(false);

  // STATE: DATA TEMP
  const [optionData, setOptionData] = useState<ComboboxItem[]>([]);

  // REF: OPT SCROLL
  const scrollRef = useRef<HTMLDivElement>(null);

  const [defaultKey] = useState(
    !key || ["number", "bigint"].includes(typeof key)
      ? cuid2.createId()
      : key.toString(),
  );

  // HOOK: FETCHER
  const {
    data: responseData,
    load: loadFn,
    state,
  } = useFetcher<TLoader>({ key: defaultKey });

  // HOOK: DEBOUNCE QUERY PARAMS (250ms)
  const [queryParams, setQueryParams] = useState<queryParamType & TParams>({
    s: "",
    limit: 50,
    page: 0,
  } as queryParamType & TParams);

  //   RECURSIVE: HANDLE FOCUS
  useDidUpdate(() => {
    if (!focus || optionData.length > 1) return;
    const addOtherParams =
      !!otherParams && typeof otherParams == "object" ? otherParams : {};
    setQueryParams((e) => ({ ...e, s: search, ...addOtherParams, page: 0 }));
  }, [focus]);

  //   RECURSIVE: HANDLE DEMAND otherParams
  useDidUpdate(() => {
    if (!focus) return;
    const addOtherParams =
      !!otherParams && typeof otherParams == "object" ? otherParams : {};

    // reset option data when otherParams changes
    setOptionData([]);

    setQueryParams((e) => ({ ...e, s: search, ...addOtherParams, page: 0 }));
  }, [JSON.stringify(otherParams)]);

  //   RECURSIVE: HANDLE DEMAND OF SEARCH
  useDidUpdate(() => {
    const addOtherParams =
      !!otherParams && typeof otherParams == "object" ? otherParams : {};

    setQueryParams((e) => ({ ...e, s: search, ...addOtherParams, page: 0 }));
  }, [search]);

  // RECURSIVE: FETCH DATA
  useDidUpdate(() => {
    // If fetchData is not provided, exit the effect
    if (!fetchData) return;

    // Set a timeout to call loadData after a delay
    // Delay is 300ms if otherParams is true, otherwise 250ms
    const timeout = setTimeout(
      () => {
        loadData(3);
      },
      otherParams ? 500 : 350,
    );

    // Cleanup function to clear the timeout when the component unmounts or queryParams change
    return () => clearTimeout(timeout);
  }, [queryParams]);

  //   RECURSIVE: UPDATE DATA WHEN RESPONSE DATA CHANGES OR UPDATE PARAMS
  useDidUpdate(() => {
    const convertRespToOpts: ComboboxItem[] = convertToOptData() ?? [];

    if (convertRespToOpts.length)
      setOptionData((e) => mergeArrays(e, convertRespToOpts, "value"));
  }, [responseData, valueOpt, hiddenRowByValues, disabledRowByValues]);

  // ONMOUNT: LOAD CURRENT OPTS
  useEffect(() => {
    const convertRespToOpts: ComboboxItem[] = convertToOptData() ?? [];
    if (convertRespToOpts.length)
      setOptionData((e) => mergeArrays(e, convertRespToOpts, "value"));
  }, []);

  //   RECURSIVE: DETERMINE LOADING BY FETCH STATE [DEBOUNCE WHEN LOADING IS TRUE]
  useEffect(() => {
    if (state != "idle") {
      setIsLoading(true);
      return;
    }

    const timeout = setTimeout(
      () => {
        setIsLoading(false);
      },
      otherParams ? 500 : 300,
    );

    return () => {
      clearTimeout(timeout);
    };
  }, [state]);

  //   EVENT: CONVERT RESPONSE DATA TO COMBOBOXITEM[]
  function convertToOptData(): void | ComboboxItem[] {
    let convertRespToOpts: ComboboxItem[] = [];

    const validNestedKeys =
      !!dataNestedKeys && typeof responseData == "object" && !!responseData;
    let refData = responseData;

    if (validNestedKeys) {
      dataNestedKeys.split(".").forEach((key) => {
        refData = (refData ?? {})[key];
      });
    }

    if (Array.isArray(refData) && !!refData.length) {
      convertRespToOpts = refData.map((e) => {
        let label = "";
        if (Array.isArray(labelKey)) {
          label = labelKey.map((label) => e[label!].toString()).join(" - ");
        } else {
          label = e[labelKey!].toString();
        }
        return {
          label: label,
          value: (e[valueKey!]?.toString() ?? "") as string,
          disabled: (disabledRowByValues ?? []).includes(
            e[valueKey!]?.toString() ?? "",
          ),
        };
      });
    }

    // MERGE W/ VALUE OPT [HANDLE OPT DATA NOT EXISTS WHEN LOAD FORM]
    // MARK UPDATE HANDLE ISSUES OF HRIS-407
    if (valueOpt && valueOpt.value == (form.values as any)[name])
      convertRespToOpts = mergeArrays(convertRespToOpts, [valueOpt], "value");

    // HIDDEN OPT WHEN MATCH ON VALUE ARRAY
    if (hiddenRowByValues)
      return convertRespToOpts.filter((e) =>
        hiddenRowByValues.includes(e.value),
      );

    return convertRespToOpts;
  }

  // EVENT: TO LOAD DATA WITH A RETRY MECANISM
  const loadData = (limit: number, count?: number) => {
    if (!focus) return;

    try {
      // Call the loader function with the constructed URL
      loadFn(urlPath + toQueryString(queryParams));
    } catch (e) {
      // Log the error with the current retry count and limit
      Logger.error(e, {
        title: `${name} select error ( ${count} / ${limit} )`,
      });

      // Retry loading data if the retry count is less than the limit
      loadData(limit, count ? count + 1 : 1);
    }
  };

  // EVENT: SCROLL DOWN
  function listenScroll(e: React.RefObject<HTMLDivElement>) {
    if (!e.current) {
      return;
    }

    // SCROLL COOR
    const scrollPos = e.current.scrollTop + e.current.clientHeight;

    // LOAD DATA WHEN OVER DOWN
    if (Math.ceil(scrollPos) >= e.current?.scrollHeight)
      setQueryParams((p) => ({ ...p, page: p.page + 1 }));
  }

  return (
    <Group pos="relative" w="100%">
      <Select
        key={key}
        size="sm"
        styles={{
          label: {
            fontWeight: 600,
          },
          root: {
            width: "100%",
          },
        }}
        renderOption={(e) => (
          <>
            {e.checked ? <Icon icon="tabler:check" className="w-3" /> : null}
            {stringIsEmpty(search) || !searchable || e.checked ? (
              e.option.label
            ) : (
              <p>
                {e.option.label
                  .split(new RegExp(`(${search})`, "gi"))
                  .map((text, i) =>
                    text.toLowerCase() === search.toLowerCase() ? (
                      <span key={i} className="bg-amber-200 text-rose-600">
                        {text}
                      </span>
                    ) : (
                      <span key={i}>{text}</span>
                    ),
                  )}
              </p>
            )}
          </>
        )}
        data={fetchData ? optionData : data}
        autoComplete="off"
        searchable={searchable}
        searchValue={search}
        onSearchChange={(s) => setSearch(s)}
        comboboxProps={{ withinPortal: false }}
        scrollAreaProps={{
          styles: {
            root: {
              ...(optMaxHeight ? { maxHeight: optMaxHeight } : {}),
            },
          },
          viewportRef: scrollRef,
          onScrollPositionChange: () => listenScroll(scrollRef),
        }}
        rightSection={isLoading ? <Loader size="xs" color="dark" /> : undefined}
        label={label}
        {...props}
        aria-label={
          typeof label == "string" && !params["aria-label"]
            ? label
            : params["aria-label"]
        }
        {...form.getInputProps(name)}
        onFocus={(e) => {
          form.getInputProps(name).onFocus(e);
          if (!focus) setFocus(true);
        }}
        onBlur={(e) => {
          form.getInputProps(name).onBlur(e);
          setFocus(false);
        }}
        error={error ?? form.getInputProps(name).error}
        onChange={(e, opt) => {
          form.getInputProps(name).onChange(e);

          if (typeof onChange == "function") onChange(e, opt);
        }}
      />
    </Group>
  );
}

export default InputSelect;
